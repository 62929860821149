import styled from "styled-components"

export const BoxPartnerSection = styled.div`
  img {
    width: 100%;
    margin-bottom: 1rem;
  }

  p {
    width: 100%;
    margin: 0 auto;
  }

  .rec-dot {
    box-shadow: 0 0 1px 1px rgb(255 255 255);
  }

  .rec-dot_active {
    box-shadow: 0 0 1px 3px rgb(255 255 255);
    background: #fff;
  }

  .item-carousel {
    max-width: 450px;

    .image-box{
      margin-bottom: 1rem;
    }
  }
`
