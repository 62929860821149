import React from "react"
import Carousel from "react-elastic-carousel"
import { BoxPartnerSection } from "./styles"
import { StaticImage } from "gatsby-plugin-image"

export function SectionPartners() {
  return (
    <BoxPartnerSection>
      <Carousel showArrows={false} pagination={true}>
        <div className="item-carousel">
          <StaticImage className="image-box" src="../../../static/partner-section-jvb.png" title="JVB"/>
          <p>A JVB provê soluções de TI para os melhores resultados dos negócios, atendendo as necessidades de nossos clientes através de soluções compatíveis e serviços customizados.</p>
        </div>
        <div className="item-carousel">
          <StaticImage className="image-box" src="../../../static/partner-section-consuldata.png" title="ConsulData"/>
          <p>A ConsulData oferece soluções completas em Tecnologia da Informação e atende desde a fase preparatória até a gestão dos negócios.</p>
        </div>
        <div className="item-carousel">
          <StaticImage className="image-box" src="../../../static/partner-section-ibox.png" title="iBoxServices"/>
          <p>iBox é uma nova Plataforma de Serviços de conectividade que permite ao cliente se conectar com diferentes instituições/empresas.</p>
        </div>
      </Carousel>
    </BoxPartnerSection>
  )
}
