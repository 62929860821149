import React from "react"
import Carousel from "react-elastic-carousel"
import { BoxPartner, Container } from "../Partners/styles"
import { StaticImage } from "gatsby-plugin-image"

export function Partners() {

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 5 },
    { width: 1200, itemsToShow: 5 },
  ]

  const carouselRef = React.useRef(null)
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0)
    }
  }

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(8)
    }
  }

  return (
    <Container>
      <BoxPartner>
        <Carousel
          ref={carouselRef}
          onPrevStart={onPrevStart}
          onNextStart={onNextStart}
          itemsToShow={5}
          pagination={false}
          breakPoints={breakPoints}
          disableArrowsOnEnd={false}
        >
          <div>
            <StaticImage className="img-item" src="../../../static/partner-accor-hotels.png" alt="" title="Accor Hotels"/>
          </div>
          <div>
            <StaticImage className="img-item" src="../../../static/partner-azul.png" title="Azul" alt=""/>
          </div>
          <div>
            <StaticImage className="img-item" src="../../../static/partner-crefisa.png" title="Crefisa" alt=""/>
          </div>
          <div>
            <StaticImage className="img-item" src="../../../static/partner-edenred.png" title="Edenred" alt=""/>
          </div>
          <div>
            <StaticImage className="img-item" src="../../../static/partner-itau.png" title="Itau" alt=""/>
          </div>
          <div>
            <StaticImage className="img-item" src="../../../static/partner-lider.png" title="Seguradora Líder" alt=""/>
          </div>
          <div>
            <StaticImage className="img-item" src="../../../static/partner-sodexo.png" title="Sodexo" alt=""/>
          </div>
          <div>
            <StaticImage className="img-item" src="../../../static/partner-sky.png" title="Sky" alt=""/>
          </div>
        </Carousel>
      </BoxPartner>
    </Container>
  )
}
