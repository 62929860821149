import React from "react"
import { Cookie } from "../components/Cookie"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import { Sections } from "../components/Sections"
import Seo from "../components/Seo"
import { GlobalStyle } from "../styles/global"

export default function Home() {
  return (
    <>
      <Cookie />
      <Seo title="Home" />
      <Header />
      <Sections />
      <GlobalStyle />
      <Footer />
    </>
  )
}
