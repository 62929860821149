import React from "react"
import CookieConsent from "react-cookie-consent"
import Sticky from "react-sticky-el"
import "./styles.css"

export function Cookie() {
  return (
    <Sticky>
      <CookieConsent
        className="consent-cookie-box js-sticky-widget"
        location="top"
        buttonText="Aceitar"
        buttonStyle={{
          backgroundColor: "transparent",
          color: "rgb(254 254 254)",
          border: "1px solid rgb(254 254 254)",
          borderRadius: "4px",
          fontSize: "1.1rem",
        }}
        cookieName="consentCookieAccept"
        expires={150}
        style={{
          fontSize: "1rem",
          lineHeight: "1.3rem",
          fontWeight: "normal",
          background: "rgb(7 30 52 / 55%)",
          color: "rgb(254 254 254)",
          backdropFilter: "blur(15px)",
          alignItems: "center",
          textAlign: "center",
          zIndex: "999999",
          position: "inherit"
        }}
      >
        Nós utilizamos cookies e outras tecnologias semelhantes para personalizar
        e melhorar a sua experiência conosco. Para mais informações sobre cookies
        bem como para exercer o seu direito de desabilitá-los, consulte a nossa{" "}
        <a
          href="/politicadeprivacidade"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#3fb6cc", fontWeight: "600" }}
        >
          Política de Privacidade
        </a>
        . Ao clicar no botão ao lado, você concorda com todos os cookies.
      </CookieConsent>
    </Sticky>
  )
}
