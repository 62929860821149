import styled from "styled-components";

import imgCircleArrow from "../../../static/circle-arrow.svg";

export const Container = styled.div`
  width: 80%;
  margin: 2rem auto;
`;

export const BoxPartner = styled.div`
  .rec-arrow-left {
    background: url(${imgCircleArrow}) center center/25px no-repeat;
    max-width: 30px;
    font-size: 0;
    border: none;
    box-shadow: none;
    &:hover,
    &:focus {
      filter: brightness(2);
      background-color: inherit;
      box-shadow: none;
    }
  }
  .rec-arrow-right {
    background: url(${imgCircleArrow}) center center/25px no-repeat;
    max-width: 30px;
    font-size: 0;
    border: none;
    box-shadow: none;
    transform: rotate(180deg);
    &:hover,
    &:focus {
      filter: brightness(2);
      background-color: inherit;
      box-shadow: none;
    }
  }

  .img-item {
    height:100px;
    width: fit-content;
    max-width: inherit;

    img{
      width: fit-content!important;
      margin-inline: auto!important;
    }
  }
`;
